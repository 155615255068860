import React, { useState } from "react";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";

import { loginToStatsApp, setBearerToken } from "../service";

const Login = ({ loginCallback }) => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      const { data: { token } = {} } = await loginToStatsApp(password);

      Cookies.set("token", token, { expires: 1 });
      setBearerToken(token);

      loginCallback();
    } catch (error) {
      console.error(error);
      setError("Invalid password");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen bg-gray-300 flex items-center justify-center">
      <div className="block">
        <form onSubmit={handleLogin} className="mb-2 flex gap-1 flex-col min-w-80">
          <input
            type="password"
            placeholder="Password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => {
              setError("");
              setPassword(e.target.value);
            }}
            className="px-4 py-3 rounded-lg border border-gray-400 text-xl w-full outline-none"
          />
          <button
            type="submit"
            onClick={handleLogin}
            disabled={loading}
            className="px-4 py-3 rounded-lg text-white font-bold text-xl bg-gray-400 border-gray-400 w-full outline-none hover:bg-gray-500 transition-all">
            {loading ? <CircularProgress color="#fff" /> : "Login"}
          </button>
        </form>

        {error && <p className="text-lg text-red-600">{error}</p>}
      </div>
    </div>
  );
};

export default Login;
