import React from "react";

import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const RangePicker = ({ value, onChange }) => {
  return (
    <div className="w-full flex justify-center my-5">
      <DateRangePicker ranges={[value]} onChange={onChange} maxDate={new Date(Date.now())} />
    </div>
  );
};

export default RangePicker;
