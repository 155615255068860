import React from "react";

import Select from "react-select";

const SelectInput = ({ label, options, value, onChange }) => {
  return (
    <label htmlFor={label} className="block min-w-60">
      <span className="block text-lg font-semibold">{label}</span>
      <Select options={options} value={value} onChange={onChange} />
    </label>
  );
};

export default SelectInput;
