import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

import Login from "./components/login";
import RangePicker from "./components/rangePicker";
import SelectInput from "./components/selectInput";
import Table from "./components/table";

import { Cancel } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

import { checkToken, getStats, setBearerToken } from "./service";

const getStartOfDay = (date) => {
  const tempDate = new Date(date);
  return new Date(tempDate.setHours(0, 0, 0, 0));
};

const getEndOfDay = (date) => {
  const tempDate = new Date(date);
  return new Date(tempDate.setHours(23, 59, 59, 999));
};

function App() {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState([]);
  const [dateRange, setDateRange] = useState({
    key: "selection",
    startDate: getStartOfDay(new Date()),
    endDate: getEndOfDay(new Date()),
  });
  const [queryFilters, setQueryFilters] = useState({});
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  const handleSelectRange = (ranges) => {
    setDateRange(ranges?.selection);
    setQueryFilters({
      ...queryFilters,
      start: getStartOfDay(ranges?.selection.startDate),
      end: getEndOfDay(ranges?.selection.endDate),
    });
  };

  const fetchStatsData = async () => {
    try {
      setLoading(true);

      const { data: { stats, availableFilters } = {} } = await getStats({
        start: dateRange.startDate,
        end: dateRange.endDate,
        ...queryFilters,
      });

      const tempFilters = Object.keys(availableFilters)
        .map((filter) =>
          availableFilters[filter]?.length > 0
            ? { label: filter, values: availableFilters[filter] }
            : null,
        )
        .filter((filter) => filter !== null);

      setFilters(tempFilters);
      setColumns(tempFilters.map((filter) => filter.label));
      setData(stats);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loggedIn) return;

    fetchStatsData();
  }, [queryFilters, loggedIn]);

  useEffect(() => {
    try {
      // Fetch token from cookies
      const token = Cookies.get("token");
      setBearerToken(token);

      (async () => {
        try {
          // Check if token is valid or not
          await checkToken();
          setLoggedIn(true);
        } catch (error) {
          setLoggedIn(false);
        }
      })();
    } catch (error) {
      setLoggedIn(false);
    }
  }, []);

  if (!loggedIn) return <Login loginCallback={() => setLoggedIn(true)} />;

  return (
    <div className="container mx-auto p-2">
      <h1 className="text-5xl text-center">Mission Future Stats</h1>

      <RangePicker value={dateRange} onChange={handleSelectRange} />

      {!loading && (
        <>
          <div className="flex justify-start gap-4 my-5 w-full flex-wrap">
            {filters?.map((filter, index) => {
              return (
                <SelectInput
                  key={index}
                  label={filter.label}
                  options={filter.values.map((value) => ({ value, label: value }))}
                  value={{ label: queryFilters[filter.label], value: queryFilters[filter.label] }}
                  onChange={({ value }) =>
                    setQueryFilters({
                      ...queryFilters,
                      [filter.label]: value,
                    })
                  }
                />
              );
            })}
          </div>

          <div className="flex justify-start gap-4 my-5 w-full flex-wrap">
            {Object.keys(queryFilters).map((filter) => {
              if (filter === "start" || filter === "end" || filter === "orgPrefix") return null;

              return (
                <span
                  className="px-4 py-2 bg-slate-200 rounded-full text-lg font-semibold flex gap-2 items-center justify-center cursor-pointer transition-all hover:bg-slate-300"
                  onClick={() => {
                    const { [filter]: value, ...rest } = queryFilters;
                    setQueryFilters(rest);
                  }}>
                  {filter}:<span className="text-blue-500">{queryFilters[filter]}</span>
                  <span className="text-slate-500">
                    <Cancel />
                  </span>
                </span>
              );
            })}

            <button
              className="px-4 py-2 rounded-full bg-blue-500 text-white font-semibold text-lg cursor-pointer transition-all hover:bg-blue-600"
              onClick={() =>
                setQueryFilters({
                  start: dateRange.startDate,
                  end: dateRange.endDate,
                })
              }>
              Reset All
            </button>
          </div>

          <div className="my-12">
            <Table
              title={`${dateRange.startDate.toDateString()} - ${dateRange.endDate.toDateString()}`}
              columns={columns}
              data={data}
              options={{ filterType: "dropdown" }}
            />
          </div>
        </>
      )}

      {loading && (
        <div className="text-center mt-24">
          <CircularProgress size={100} />
        </div>
      )}
    </div>
  );
}

export default App;
