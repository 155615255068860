import React, { useState, useEffect } from "react";

import MUIDataTable from "mui-datatables";

const baseColumns = [
  "application",
  "event",
  "pageURL",
  "pageTitle",
  "orgPrefix",
  "teamType",
  "accountID",
  "projectID",
  "tanID",
  "professionName",
  "strengthName",
  "roleName",
  "competenceName",
  "aptitudeName",
  "championID",
  "championName",
  "championProfileURL",
  "videoIndex",
  "videoTitle",
  "clickTarget",
  "clickURL",
  "slideViewIndex",
  "slideTitle",
  "workshopType",
  "workshopPersons",
  "workshopDate",
  "workshopLanguage",
  "timeSpent",
];

const Table = ({ title, columns, data, options }) => {
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [parsedData, setParsedData] = useState([]);

  useEffect(() => {
    const parsedColumns = [...baseColumns].filter((column) => columns.includes(column));
    setUpdatedColumns(parsedColumns);

    const parsedDataRows = [];
    data.forEach(({ count, _id: stats }) => {
      const parsedData = parsedColumns.map((column) => {
        return stats[column];
      });
      parsedData.push(count);
      parsedDataRows.push(parsedData);
    });
    setParsedData(parsedDataRows);
  }, [columns, data]);

  return (
    <MUIDataTable
      title={title}
      columns={[...updatedColumns, "count"]}
      data={parsedData}
      options={{ ...options, selectableRows: "none" }}
    />
  );
};

export default Table;
