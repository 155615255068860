import axios from "axios";

const baseAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const setBearerToken = (token) => {
  baseAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const getStats = (query) => {
  return baseAxios.get(`/stats`, { params: query });
};

export const loginToStatsApp = (password) => {
  return baseAxios.post(`/auth/stats`, { password });
};

export const checkToken = () => {
  return baseAxios.get(`/auth/token-check`);
};
